const SETTINGS_APPLY_DELAY = 20;

function applySettings(settings) {
    const style = document.createElement('style');
    style.textContent = `
:root {
    --default-text-primary: #000000;
    --default-text-secondary: #666666;
    --default-button-background: #4CAF50;
    --default-button-text: #FFFFFF;
    --default-input-border: #cccccc;
    --default-form-background-color: #f9f9f9;
    --default-form-border-color: #dddddd;
    --default-tab-item-text-color: #333333;
    --default-tab-item-active-text-color: #000000;
    --default-tab-item-border-color: #eeeeee;
    --default-tab-item-active-border-color: #000000;
    --default-button-border-color: #4CAF50;
    --default-button-hover-background: #45a049;
    --default-button-hover-text: #FFFFFF;
    --default-button-hover-border: #45a049;
    --default-input-background: #FFFFFF;
    --default-volume-bar-background: #4CAF50;
    --default-headings-font: 'Arial, sans-serif';
    --default-body-font: 'Arial, sans-serif';
    --default-navigation-font: 'Arial, sans-serif';
    --default-button-font: 'Arial, sans-serif';
    --default-homepage-background: none;
    --default-homepage-headphone: url('img/headphone.png');
    --default-homepage-mic: url('img/mic.png');
    --default-homepage-enter: url('img/enter.png');
    --default-homepage-enter-hover: url('img/enterover.png');

    /* COLOR */
    --text-primary: ${settings.colors.textPrimary || 'var(--default-text-primary)'};
    --text-secondary: ${settings.colors.textSecondary || 'var(--default-text-secondary)'};
    --button-background: ${settings.colors.buttonBackground || 'var(--default-button-background)'};
    --button-text: ${settings.colors.buttonText || 'var(--default-button-text)'};
    --input-border: ${settings.colors.inputBorder || 'var(--default-input-border)'};
    --form-background-color: ${settings.colors.formBackgroundColor || 'var(--default-form-background-color)'};
    --form-border-color: ${settings.colors.formBorderColor || 'var(--default-form-border-color)'};
    --tab-item-text-color: ${settings.colors.tabItemTextColor || 'var(--default-tab-item-text-color)'};
    --tab-item-active-text-color: ${settings.colors.tabItemActiveTextColor || 'var(--default-tab-item-active-text-color)'};
    --tab-item-border-color: ${settings.colors.tabItemBorderColor || 'var(--default-tab-item-border-color)'};
    --tab-item-active-border-color: ${settings.colors.tabItemActiveBorderColor || 'var(--default-tab-item-active-border-color)'};
    --button-border-color: ${settings.colors.buttonBorder || 'var(--default-button-border-color)'};
    --button-hover-background: ${settings.colors.buttonHoverBackground || 'var(--default-button-hover-background)'};
    --button-hover-text: ${settings.colors.buttonHoverText || 'var(--default-button-hover-text)'};
    --button-hover-border: ${settings.colors.buttonHoverBorder || 'var(--default-button-hover-border)'};
    --input-background: ${settings.colors.inputBackground || 'var(--default-input-background)'};
    --volume-bar-background: ${settings.colors.volumeBarBackground || 'var(--default-volume-bar-background)'};

    /* Fonts */
    --headings-font: ${settings.fonts.headingsFont || 'var(--default-headings-font)'};
    --body-font: ${settings.fonts.bodyFont || 'var(--default-body-font)'};
    --navigation-font: ${settings.fonts.navigationFont || 'var(--default-navigation-font)'};
    --button-font: ${settings.fonts.buttonFont || 'var(--default-button-font)'};

    /* Homepage variables */
    --homepage-background: ${settings.homepage.background ? `url('${MAIN_URL}/${settings.homepage.background}')` : 'var(--default-homepage-background)'};
    --homepage-headphone: ${settings.homepage.headphone ? `url('${MAIN_URL}/${settings.homepage.headphone}')` : 'var(--default-homepage-headphone)'};
    --homepage-mic: ${settings.homepage.mic ? `url('${MAIN_URL}/${settings.homepage.mic}')` : 'var(--default-homepage-mic)'};
    --homepage-enter: ${settings.homepage.enterImage ? `url('${MAIN_URL}/${settings.homepage.enterImage}')` : 'var(--default-homepage-enter)'};
    --homepage-enter-hover: ${settings.homepage.enterHover ? `url('${MAIN_URL}/${settings.homepage.enterHover}')` : 'var(--default-homepage-enter-hover)'};
}

    /* The rest of the CSS remains the same as in the previous version */
    body {
        color: var(--text-secondary);  
        font-family: var(--body-font);
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: var(--headings-font);
        color: var(--text-primary);
    }

    nav {
        font-family: var(--navigation-font);
    }

    .form-element button {
        font-family: var(--button-font);
        background-color: var(--button-background);
        color: var(--button-text);
        border-color: var(--button-border-color);
    }

    .form-element button:hover {
        background-color: var(--button-hover-background);
        color: var(--button-hover-text);
        border-color: var(--button-hover-border);
    }

    .loginBgArea {
        background-color: var(--form-background-color);
    }

    .form {
        border-color: var(--form-border-color);
    }

    .form-element input[type=email], .form-element input[type=password], .form-element input[type=text] {
        background-color: var(--input-background);
        border-color: var(--input-border);
    }

    .forgot-pass-body, .login, .register {
        color: var(--text-secondary);  
    }

    .tab-header > div {
        color: var(--tab-item-text-color);
        border-color: var(--tab-item-border-color);
    }

    .tab-header > div.active {
        color: var(--tab-item-active-text-color);
        border-color: var(--tab-item-active-border-color);
    }

    .center-volume .noUi-connect {
        background-color: var(--volume-bar-background);
    }

    ::placeholder {
        color: var(--text-secondary);  
    }

    ::-webkit-input-placeholder {
        color: var(--text-secondary);  
    }

    :-moz-placeholder {
        color: var(--text-secondary);  
    }

    ::-moz-placeholder {
        color: var(--text-secondary);  
    }

    :-ms-input-placeholder {
        color: var(--text-secondary);  
    }

    .player-headphone-section {
        background-image: var(--homepage-headphone);
    }

    .player-mic {
        background-image: var(--homepage-mic);
    }    

    .triger-button {
        background-image: var(--homepage-enter);
    }    

    .triger-button:hover {
        background-image: var(--homepage-enter-hover);
    }
  
    `;
    document.head.appendChild(style);
}

// update google font
function loadFonts(fonts) {
    if (!Array.isArray(fonts)) {
        fonts = [fonts];
    }

    WebFont.load({
        google: {
            families: fonts
        },
        active: function () {
            console.log('Fonts have been loaded');
        },
        inactive: function () {
            console.log('Failed to load fonts');
        }
    });
}
function sitetilefab(settings){
 const siteTitle = settings.title;
 const ismastersite = settings.isMasterSite;
 const masterFaviconUrl = settings.settings.masterWebsite.favicon;
 document.title=siteTitle;
 if(ismastersite){
   setFavicon(masterFaviconUrl);
 }
}
function setFavicon(url) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = url;
}
async function initializeSiteSettings() {
    const SITE_ID = getSiteIdFromStorage();
    if (SITE_ID) {
        const settings = await fetchSiteSettings(SITE_ID);
        if (settings) {
            setTimeout(() => {
                applySettings(settings);
                sitetilefab(settings);
                loadFonts(settings.settings.fonts.headingsFont);
                loadFonts(settings.settings.fonts.bodyFont);
                loadFonts(settings.settings.fonts.buttonFont);
            }, SETTINGS_APPLY_DELAY);
        }
    }
}
window.addEventListener('load', initializeSiteSettings);