// Site Management Module

const MAIN_URL = window.APP_CONFIG.MAIN_URL.trim().replace(/\/+$/, '');
const protocol = window.location.protocol;
class SiteManager {
    constructor() {
        this.BASE_URL = this.getBaseUrl();
    }

    getBaseUrl() {
        let url = typeof window !== 'undefined' ? window.location.origin : '';
        return this.cleanUrl(url);
    }
    cleanUrl(url) {
        let clean = url.replace(/^(https?:\/\/)?(www\.)?/, '');
        clean = clean.replace(/\/$/, '');
        return clean;
    }

    async getWebsiteInfo() {
        try {
            const response = await fetch(`${MAIN_URL}/api/websites-info/`);
            if (!response.ok) throw new Error('Failed to fetch website info');
            return await response.json();
        } catch (error) {
            console.error('Error fetching website info:', error);
            return null;
        }
    }

    async findSiteId() {
        try {
            const siteInfo = await this.getWebsiteInfo();
            if (!siteInfo) return null;

            const matchingSite = siteInfo.find(site => site.siteUrl === this.BASE_URL);
            if (matchingSite) {
                return matchingSite.id;
            } else {
                console.log('No matching site found');
                return null;
            }
        } catch (error) {
            console.error('Error finding site ID:', error);
            return null;
        }
    }

    async fetchSiteSettings(siteId) {
        if (!siteId) {
            console.warn('Site ID is missing. Unable to fetch site settings.');
            return null;
        }

        try {
            const response = await fetch(`${MAIN_URL}/api/site-settings/${siteId}`);
            console.log(response);
            if (!response.ok) {
                if (response.status === 404) {
                    console.warn(`Site settings not found for ID: ${siteId}. Using default settings.`);
                } else {
                    console.warn(`Failed to fetch site settings. Status: ${response.status}. Using default settings.`);
                }
                return null;
            }
            return await response.json();
        } catch (error) {
            console.warn('Network error while fetching site settings. Using default settings.', error.message);
            return null;
        }
    }

    async sendInfoToServer(info) {
        try {
            const response = await fetch(`${MAIN_URL}/admin/track-user`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(info),
            });

            if (!response.ok) throw new Error('Failed to send user info');

            const data = await response.json();
            console.log('User info sent successfully:', data);
            return data;
        } catch (error) {
            console.error('Error sending user info:', error);
            return null;
        }
    }

    async updateSiteId() {
        const siteId = await this.findSiteId();
        if (siteId) {
            // const storageKey = this.getStorageKey();
            // const storedSiteId = localStorage.getItem(storageKey); // Use localStorage here

            // if (!storedSiteId || storedSiteId !== siteId) {
            //     localStorage.setItem(storageKey, siteId); // Update siteId in localStorage if changed
            //     console.log("Updated siteId in localStorage:", siteId);
            // }
            this.setSiteIdToStorage(siteId)
        }
    }

    setSiteIdToStorage(siteId) {
        const storageKey = this.getStorageKey();
        localStorage.setItem(storageKey, siteId); // Store SITE_ID in localStorage
    }

    getSiteIdFromStorage() {
        const storageKey = this.getStorageKey();
        const siteId = localStorage.getItem(storageKey); // Persist SITE_ID across sessions
        return typeof siteId === 'string' && siteId.trim() !== '' ? siteId : null;
    }

    getStorageKey() {
        return `SITE_ID_${window.location.hostname}`;
    }

}

// Initialize the SiteManager
const siteManager = new SiteManager();

// Event listener

// Expose necessary methods and properties to the global scope
window.MAIN_URL = MAIN_URL;
window.BASE_URL = siteManager.BASE_URL;
window.siteManager = siteManager;
window.getWebsiteInfo = () => siteManager.getWebsiteInfo();
window.findTheExactIdForThisSite = () => siteManager.findSiteId();
window.fetchSiteSettings = (siteId) => siteManager.fetchSiteSettings(siteId);
window.sendInfoToServer = (info) => siteManager.sendInfoToServer(info);
window.getSiteIdFromStorage = () => siteManager.getSiteIdFromStorage();

// Export the SiteManager instance for module usage
export default siteManager;