(async function() {
    const websiteInfoArray = await getWebsiteInfo();

    if (!Array.isArray(websiteInfoArray) || websiteInfoArray.length === 0) {
        console.log('No website information available. Tracking disabled.');
        return;
    }

     const websiteInfo = websiteInfoArray.find(site => site.siteUrl === BASE_URL);
     
    if (!websiteInfo || websiteInfo.siteStatus !== 'active') {
        console.log('Website is not active or not found. Tracking disabled.');
        return;
    }

    const websiteId = websiteInfo.id;

    let pageVisits = {};
    let totalVisits = 0;
    let sessionId = '';
    let lastTrackedPath = '';

    async function getUserInfo() {
        const info = {
            userAgent: navigator.userAgent,
            currentPage: window.location.pathname,
            pageVisits: pageVisits,
            totalVisits: totalVisits,
            sessionId: sessionId,
            websiteId: websiteId,
            userid: localStorage.getItem('user_id') || 'No user.'
        };

        // Detect device type
        info.device = /Mobi|Android/i.test(navigator.userAgent) ? 'Mobile' : 
                      /Tablet|iPad/i.test(navigator.userAgent) ? 'Tablet' : 'Desktop';

        // Detect browser and OS (code remains the same)
        // Detect browser
        const browserRegexes = [
            {name: 'Chrome', regex: /Chrome\/([0-9.]+)/},
            {name: 'Firefox', regex: /Firefox\/([0-9.]+)/},
            {name: 'Safari', regex: /Version\/([0-9.]+).*Safari/},
            {name: 'Edge', regex: /Edg\/([0-9.]+)/},
            {name: 'IE', regex: /Trident\/7.0.*rv:([0-9.]+).*\).*Gecko$/},
            {name: 'Opera', regex: /OPR\/([0-9.]+)/}
        ];

        for (let browser of browserRegexes) {
            const match = navigator.userAgent.match(browser.regex);
            if (match) {
                info.browser = { name: browser.name, version: match[1] };
                break;
            }
        }

        // Detect OS
        const osRegexes = [
            {name: 'Windows', regex: /Windows NT ([0-9.]+)/},
            {name: 'Mac', regex: /Mac OS X ([0-9._]+)/},
            {name: 'iOS', regex: /iPhone OS ([0-9._]+)/},
            {name: 'Android', regex: /Android ([0-9.]+)/},
            {name: 'Linux', regex: /Linux/}
        ];

        for (let os of osRegexes) {
            const match = navigator.userAgent.match(os.regex);
            if (match) {
                info.os = { name: os.name, version: match[1] ? match[1].replace(/_/g, '.') : '' };
                break;
            }
        }

        // Get IP address
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            info.ip = data.ip;
        } catch (error) {
            console.error('Error fetching IP:', error);
        }

        return info;
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    function setCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value};${expires};path=/`;
    }

    async function trackPageVisit() {
        const currentPage = window.location.pathname;
        
        if (currentPage !== lastTrackedPath) {
            lastTrackedPath = currentPage;
            pageVisits[currentPage] = (pageVisits[currentPage] || 0) + 1;
            totalVisits++;

            sessionId = getCookie('sessionId');
            if (!sessionId) {
                sessionId = 'session_' + Date.now();
                setCookie('sessionId', sessionId, 1);
            }

            const userInfo = await getUserInfo();
            sendInfoToServer(userInfo);
        }
    }

    window.addEventListener('load', trackPageVisit);
    window.addEventListener('popstate', trackPageVisit);

    const originalPushState = history.pushState;
    history.pushState = function() {
        originalPushState.apply(this, arguments);
        trackPageVisit();
    };

    trackPageVisit();
})();