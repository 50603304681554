
// Wrap the entire script in an IIFE
(function() {
    document.documentElement.style.display = 'none';
    async function fetchBlockedIps(){
        try {
            const response = await fetch(`${MAIN_URL}/api/blacklist-ip`);
            if (!response.ok) throw new Error('Failed to fetch blacklisted IPs');
            const data = await response.json();
            return data.blacklistedIps;
        } catch (error) {
            console.error('Error fetching blacklisted IPs:', error);
            return [];
        }
    };

    const fetchClientIp = async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            return data.ip;
        } catch (error) {
            console.error('Error fetching IP:', error);
            return null;
        }
    };

    const checkIpBlacklist = async () => {
        try {
            const clientIp = await fetchClientIp();
            const blockedIps = await fetchBlockedIps();

            if (clientIp && blockedIps.includes(clientIp)) {
                document.open();
                document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Access Denied</title>
                        <style>
                            body {
                                background-color: white;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100vh;
                                margin: 0;
                                font-family: Arial, sans-serif;
                            }
                            .error-message {
                                text-align: center;
                                color: #333;
                            }
                        </style>
                    </head>
                    <body>
                        <div class="error-message">
                            <h1>Access Denied</h1>
                            <p>Your IP address is blacklisted.</p>
                        </div>
                    </body>
                    </html>
                `);
                document.close();
            } else {
                // IP is not blacklisted, allow content to display
                document.documentElement.style.display = ''
            }
        } catch (error) {
            console.error('Error checking blacklisted IP:', error);
            // In case of error, show a generic message
            document.open();
            document.write('<h1>Welcome</h1><p>There was an error processing your request.</p>');
            document.close();
        }
    };

    // Execute the IP check immediately
    checkIpBlacklist();
})();